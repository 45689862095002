<div class="container-fluid row margin-bottom-15">
  <div class="col-2 full-height panel-box">
    <cms-cpml-dashboard-view-by-date-filter [viewBy]="currentFilterState.viewBy" (onChangeViewByDateFilter)="onChangeViewByDateFilter($event)" [resetEventEmitter]="resetEventEmitter"></cms-cpml-dashboard-view-by-date-filter>
  </div>
  <div class="col-10">
    <div class="panel-box full-height" style="max-width: 850px;">
      <form [formGroup]="dashboardFilterFormGroup">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-3">
            <cms-filter-multiselect label="Trade Repository" formControlName="tradeRepository" [options]="tradeRepositoryOptions" [resetEventEmitter]="resetEventEmitter"
                                    [parentFormGroup]="dashboardFilterFormGroup" (onChangeOptions)="onChangeTradeRepostoryOptions($event);" ngDefaultControl></cms-filter-multiselect>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <cms-filter-multiselect label="Asset Class" formControlName="assetClass" [options]="assetClassOptions" [resetEventEmitter]="resetEventEmitter"
                                    [parentFormGroup]="dashboardFilterFormGroup" (onChangeOptions)="onChangeAssetClassOptions($event);" ngDefaultControl></cms-filter-multiselect>
          </div>
          <div class="col-12 col-md-4 col-lg-3">
            <cms-filter-multiselect label="OTC vs. ETD" formControlName="otcEtd" [options]="otcEtdOptions" [resetEventEmitter]="resetEventEmitter"
                                    [parentFormGroup]="dashboardFilterFormGroup" (onChangeOptions)="onChangeOtcEtdOptions($event);" ngDefaultControl></cms-filter-multiselect>
          </div>
          <div class="col-auto flex align-items-end ">
            <button type="button" id="btn-search-types" class="form-control btn margin-top-15" (click)="onFullFilter()" [disabled]="hasErrors()">Search</button>
            <button type="button" id="btn-reset-types" class="form-control btn margin-top-15" (click)="onResetFilters();">Reset</button>
          </div>
        </div>
        <div class="error-note" *ngIf="hasErrors()">
          Select at least one element in marked select box.
        </div>
      </form>
    </div>
  </div>
</div>
