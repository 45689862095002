import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

// services
import { AuthenticationService } from '../auth/authentication.service';
import { OrganisationService } from '@common/organisation.service';
import { UserService } from '@common/user.service';
import { PasswordService } from './password/password.service';
// models
import { LoginResultModel } from '../auth/login-result.model';
import { Credentials } from '../auth/credentials.model';
import { StateService } from '@common/state.service';
import { Message } from 'primeng/api';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BrowserInfo } from '@common/browser-detection/browser-detection.model';
import { BrowserDetectionService } from '@common/browser-detection/browser-detection.service';

@Component({
  selector: 'cms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();

  loginForm: UntypedFormGroup;
  accessDenied: boolean;
  showLogin: boolean = true;
  errorMsg: string = '';
  error: boolean = false;
  warn: boolean = false;
  successMsg: string = '';
  messages: Message[] = [];
  vMessages: Message[] = [];
  currentBrowser: Message[] = [];
  activateToken: boolean = false;
  usernameValid: boolean = true;
  passwordValid: boolean = true;
  tokenValid: boolean = true;
  loading = false;

  validBrowser = true;
  browserInfo: BrowserInfo;
  organisationObserver: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
              private authService: AuthenticationService,
              private organisationService: OrganisationService,
              private userService: UserService,
              private router: Router, private titleService: Title,
              private passwordService: PasswordService,
              private stateService: StateService,
              private deviceService: DeviceDetectorService,
              private browserDetectionService: BrowserDetectionService) {

    this.successMsg = passwordService.getSuccessMessage() ? passwordService.getSuccessMessage() : '';
  }

  ngOnInit() {
    this.titleService.setTitle('Login');
    this.createForm();

    this.checkBrowser();

    this.authService.isAuthenticated().pipe(takeUntil(this.onDestroy$)).subscribe(
      value => {
        this.showLogin = !value;

        this.loading = false;

        if (value && this.authService.forcePassword()) {
          this.router.navigate(['/change_password_immediately'], { queryParamsHandling: 'preserve' });
        } else if (value) {
          this.router.navigate(['/help/welcome'], { queryParamsHandling: 'preserve' });
        }

      }
    );

    // set error message on dialog user info
    this.loginForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.setErrorMsg(null);
    });

    this.passwordService.getStatus().pipe(takeUntil(this.onDestroy$)).subscribe(
      status => {
        if (status.response) {
          this.handleStatusResponse(status.response);
        }
      }
    );

    this.authService.onAuthenticationError().pipe(takeUntil(this.onDestroy$))
      .subscribe(authError => {

        this.loading = false;
        if (authError) {
          this.loginError(authError);
          this.authService.resetAuthError();
        } else {
          this.resetErrors();
        }
      });

    if (!this.validBrowser) {
      const status = 'warn';
      const statusText = 'Support for this browser version (' + this.browserDetectionService.currentBrowser.browser + ' ' +
        this.browserDetectionService.currentBrowser.current + ') will soon end. Please update your browser version.';
      this.warn = true;
      this.error = false;
      this.stateService.reset();

      this.addVersionMessage(status, statusText);
    }
  }

  checkBrowser(): void {
    const deviceInfo = this.deviceService.getDeviceInfo();

    this.browserInfo = {
      browser: deviceInfo.browser,
      browserVersion: parseInt(deviceInfo.browser_version.split('.')[0], 10)
    };

    this.validBrowser = this.browserDetectionService.isBrowserSupported(this.browserInfo);
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      token: []
    });

    // login with token?
    this.activateToken = this.isTokenActive();
    if (this.activateToken) {
      this.loginForm.controls['token'].setValidators([Validators.required]);
    }
  }

  onLogin() {
    this.resetErrors();
    if (this.loginForm.valid) {

      this.organisationService.reset();
      this.userService.cleanUp();

      const credentials: Credentials = this.loginForm.value;

      this.loading = true;
      this.loginForm.disable();
      this.authService.login(credentials).pipe(takeUntil(this.onDestroy$))
        // do handling etc here
        .subscribe(
          (data) => this.loginSuccess(data), // on success
          () => {
            this.loading = false;
            this.loginForm.enable();
          }
        );
    } else {
      this.displayErrors();
    }
  }

  private displayErrors() {
    if (this.loginForm.controls.username.invalid) {
      this.messages.push({
        severity: 'error',
        summary: '',
        detail: 'Field \'username\' is required.'
      });

      this.usernameValid = false;
    }

    if (this.loginForm.controls.password.invalid) {
      this.messages.push({
        severity: 'error',
        summary: '',
        detail: 'Field \'password\' is required.'
      });

      this.passwordValid = false;
    }

    if (this.loginForm.controls.token.invalid) {
      this.messages.push({
        severity: 'error',
        summary: '',
        detail: 'Field \'token\' is required.'
      });

      this.tokenValid = false;
    }
  }

  private resetErrors() {
    this.messages = [];
    this.tokenValid = true;
    this.usernameValid = true;
    this.passwordValid = true;
    this.setErrorMsg(null);
  }

  loginSuccess(result: LoginResultModel) {
    if (result.success) {
      this.setErrorMsg(null);
      this.stateService.resetOrganisationId();
      this.organisationService.reset();
      this.organisationService.loadSelectableOrganisations();

      this.authService.isAuthenticated().pipe(takeUntil(this.onDestroy$)).subscribe(authenticated => {
        if (authenticated) {
          if (!this.validBrowser) {

            let orgId;

            this.organisationObserver = this.organisationService.getChangeNotNullOrganisationObservable().subscribe(i => {
              orgId = i.organisationId;
              this.organisationObserver.unsubscribe();
            });

            // waiting to orgId to get set
            setTimeout(() => {
              this.userService.logUnsupportedBrowser(this.browserInfo, orgId);
            }, 2000);
          }

          this.router.navigate(['help/welcome'], { queryParamsHandling: 'preserve' });
        }
      });

    } else {
      this.loginError(null);
      this.authService.logout();
    }
  }

  private isTokenActive(): boolean {
    if (this.router.url) {
      return this.router.url.indexOf('/token') > 0;
    }

    return false;
  }

  loginError(err) {

    // Exit if error is already handled
    if (err === true) {
      return;
    }
    let status = 'error';
    let statusText;

    if (err?.error?.errorMessage) {
      statusText = err?.error?.errorMessage;
    } else if (err?.statusText) {
      statusText = err?.statusText;
    } else {
      statusText = 'Login denied.'
    }

    if (err.url && err.url.toString().indexOf('login') >= 0) {
      this.warn = false;
      this.error = true;
    } else if (this.authService.hasXSRFToken()) {
      status = 'warn';
      statusText = 'Session has timed out. Please log in again.';
      this.warn = true;
      this.error = false;
      this.stateService.reset();
    }

    this.addMessage(status, statusText);
  }

  setErrorMsg(msg: string) {
    if (msg != null) {
      this.addMessage('error', msg);
      this.error = true;
    } else {
      this.error = false;
    }
  }

  showSuccessMessage(): boolean {
    return this.successMsg.length > 0;
  }

  private addMessage(severity, message) {
    if (!this.messages.some(item => item.severity === severity && item.detail === message)) {
      this.messages = [{
        severity: severity,
        summary: '',
        detail: message
      }];
    }
  }

  private addVersionMessage(severity, message) {
    if (!this.vMessages.some(item => item.severity === severity && item.detail === message)) {
      this.vMessages = [{
        severity: severity,
        summary: '',
        detail: message
      }];
    }
  }

  private handleStatusResponse(msg: string) {

    switch (msg) {
      case 'REQUEST_EXPIRED':
        this.setErrorMsg('Your password link has expired.');
        break;
      case 'USED_PASSWORD':
        this.setErrorMsg('New password has been used before.');
        break;
      case 'WEAK_PASSWORD':
        this.setErrorMsg('New password does not meet the password policy requirements.');
        break;
      case 'SUCCESS':
        this.setErrorMsg(null);
        break;
    }
  }

  isAccessDenied() {
    return this.error;
  }

  hasWarning() {
    return this.warn;
  }

  getShowLogin() {
    return this.showLogin;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.messages = [];
    this.error = false;
    this.warn = false;
  }

}
