import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Column, DefaultValues, PagerInfo } from '@common/shared/datatable.model';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { ErrRemitDataTableConfig } from './remit-datatable.config';
import { DateUtils } from '@common/shared/date.utils';
import * as FileSaver from 'file-saver';
import { OrganisationService } from '@common/organisation.service';
import { ErrRemitDataTableModel } from './remit-datatable.model';
import { RemitTransactionsService } from './remit-transactions.service';
import { defaultSorting, RemitTransactionsSearchQuery } from './remit-transactions.model';
import { DecimalPipe } from '@angular/common';
import { RemitDocumentType } from '../dashboard/filter/remit-dashboard-filter-model';
import { NavigationExtras, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { DatatableState } from '@common/datatable/datatable.model';
import { DatatableComponent } from '@common/datatable/datatable.component';
import { Table, TableService } from 'primeng/table';
import { DomHandler } from 'primeng/dom';
import { ObjectUtils } from 'primeng/utils';

@Component({
  selector: 'cms-remit-datatable',
  templateUrl: './remit-datatable.component.html',
  styleUrls: ['./remit-datatable.component.scss'],
  providers: [
    DomHandler,
    ObjectUtils,
    TableService,
    Table,
    {
      provide: Table,
      useFactory: (datalist: RemitDatatableComponent): Table => datalist.dataTableComponent.primeTable,
      deps: [
        RemitDatatableComponent
      ]
    }
  ],
})
export class RemitDatatableComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent, { static: false }) dataTableComponent: DatatableComponent;
  @Input() category: string;
  hidden: boolean = true;
  pagerInfo: PagerInfo;
  currentPage: number = 0;
  perPage: number = 25;
  docType: RemitDocumentType;
  sorting: SortingFilter = defaultSorting;
  dealsResult: ErrRemitDataTableModel = {
    rows: [], recordCount: 0, filterRange: null, searchQuery: null, columnOrder: [],
  };
  defaults = new DefaultValues();
  rowsPerPageOptions = this.defaults.getRowsPerPageOptions();
  defaultStyles = {
    'text-overflow': 'ellipsis',
    'overflow': 'hidden',
    'white-space': 'nowrap'
  };
  private onDestroy$ = new Subject();
  private tableColumns: Column[];

  constructor(public remitTransactionsService: RemitTransactionsService,
              private organisationService: OrganisationService,
              private changeDetectorRef: ChangeDetectorRef,
              private decimalPipe: DecimalPipe,
              private router: Router) {
  }

  ngOnInit(): void {
    this.hidden = true;
    this.pagerInfo = PagerInfo.calcPagerInfo(0, 0, 25);
    this.remitTransactionsService.getResetStatusChangeObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.resetView.bind(this));

    this.organisationService.getChangeOrganisationObservable()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.remitTransactionsService.resetData();
        this.resetView();
      });

    const searchQuery$ = this.remitTransactionsService.getSearchQueryChangeObservable();

    const dealsSearchFilterQuery$ = searchQuery$
      .pipe(filter(this.isValidSearchFilterQuery.bind(this)));

    const remitBucketsSearchQuery$ = searchQuery$
      .pipe(filter(this.isValidBucketSearchQuery.bind(this)));

    const utiSearchQuery$ = searchQuery$
      .pipe(filter(this.isValidUtiSearchQuery.bind(this)));

    const transactionIdSearchQuery$ = searchQuery$
      .pipe(filter(this.isValidTransactionIdSearchQuery.bind(this)));

    const contractIdSearchQuery$ = searchQuery$
      .pipe(filter(this.isValidContractIdSearchQuery.bind(this)));

    const uniqueIdSearchQuery$ = searchQuery$
      .pipe(filter(this.isValidUniqueIdSearchQuery.bind(this)));

    const refereneIdSearchQuery$ = searchQuery$
      .pipe(filter(this.isValidReferenceIdSearchQuery.bind(this)));

    const filenameSearchQuery$ = searchQuery$
      .pipe(filter(this.isValidFilenameSearchQuery.bind(this)));

    const sortingOrder$ = searchQuery$
      .pipe(filter(this.isValidSortOrder.bind(this)));

    sortingOrder$.pipe(takeUntil(this.onDestroy$)).subscribe(this.setSortOrder.bind(this));

    dealsSearchFilterQuery$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.searchByFilter.bind(this));

    remitBucketsSearchQuery$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.loadBucketDeals.bind(this));

    utiSearchQuery$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.searchByUti.bind(this));

    transactionIdSearchQuery$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.searchByTransactionId.bind(this));

    contractIdSearchQuery$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.searchByContractId.bind(this));

    uniqueIdSearchQuery$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.searchByUniquesId.bind(this));

    refereneIdSearchQuery$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.searchByReferenceId.bind(this));

    filenameSearchQuery$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.searchByFilename.bind(this));

    this.remitTransactionsService.onResetData().subscribe(reset => {
      if (reset) {
        this.dealsResult = {
          rows: [],
          recordCount: 0,
          filterRange: null,
          searchQuery: null,
          columnOrder: [],
        };
        this.sorting = defaultSorting;
        this.hidden = true;
      }
    });
  }

  onLazyLoad({ page, perPage: entriesPerPage, sortField, sortOrder }: DatatableState) {
    const pagingFilter: PagingFilter = {
      entriesPerPage,
      page
    };

    this.pagerInfo = PagerInfo.calcPagerInfo(0, page, entriesPerPage);
    this.currentPage = page;
    this.perPage = entriesPerPage;
    const columnName = sortField || defaultSorting.columnName;
    const sortingFilter: SortingFilter = { columnName, sortOrder };
    const query = this.remitTransactionsService.getCurrentSearchQuery();
    if (query) {
      this.remitTransactionsService.updateSearchQueryByPagingAndSorting(pagingFilter, sortingFilter);
    }
  }

  get cols() {
    return this.tableColumns;
  }

  get rows() {
    return this.dealsResult.rows;
  }

  hasData(): boolean {
    return this.dealsResult.rows.length > 0;
  }

  get filterRangeAsString() {
    if (this.dealsResult.filterRange) {
      const { start, end } = this.dealsResult.filterRange;
      const transformFilterDate = (date) => DateUtils.transformDateWithTimestamp(date, { defaultValue: 'Now' });
      return `${transformFilterDate(start)} - ${transformFilterDate(end)}`;
    }
    return null;
  }

  get numberOfTotalRows() {
    return this.dealsResult.recordCount;
  }

  getStyles(column: Column): { [key: string]: string } {
    return { ...this.defaultStyles, ...column.style };
  }

  private isValidSearchFilterQuery(searchQuery): boolean {
    return RemitTransactionsSearchQuery.isNotEmptySearchQuery(searchQuery)
      && searchQuery.searchMode === 'SEARCH_FILTER';
  }

  private isValidBucketSearchQuery(searchQuery): boolean {
    return RemitTransactionsSearchQuery.isNotEmptySearchQuery(searchQuery)
      && searchQuery.searchMode === 'SEARCH_BUCKET_DETAIL';
  }

  private isValidUtiSearchQuery(searchQuery): boolean {
    return RemitTransactionsSearchQuery.isNotEmptySearchQuery(searchQuery)
      && searchQuery.searchMode === 'SEARCH_UTI' && searchQuery.ids && searchQuery.ids.length > 0
      && searchQuery.ids.every(id => id.length <= 100);
  }

  private isValidTransactionIdSearchQuery(searchQuery): boolean {
    return RemitTransactionsSearchQuery.isNotEmptySearchQuery(searchQuery)
      && searchQuery.searchMode === 'SEARCH_TRANSACTION_ID' && searchQuery.ids && searchQuery.ids.length > 0
      && searchQuery.ids.every(id => id.length <= 100);
  }

  private isValidContractIdSearchQuery(searchQuery): boolean {
    return RemitTransactionsSearchQuery.isNotEmptySearchQuery(searchQuery)
      && searchQuery.searchMode === 'SEARCH_CONTRACT_ID' && searchQuery.ids && searchQuery.ids.length > 0
      && searchQuery.ids.every(id => id.length <= 100);
  }

  private isValidUniqueIdSearchQuery(searchQuery): boolean {
    return RemitTransactionsSearchQuery.isNotEmptySearchQuery(searchQuery)
      && searchQuery.searchMode === 'SEARCH_UNIQUE_ID' && searchQuery.ids && searchQuery.ids.length > 0
      && searchQuery.ids.every(id => id.length <= 100);
  }

  private isValidReferenceIdSearchQuery(searchQuery): boolean {
    return RemitTransactionsSearchQuery.isNotEmptySearchQuery(searchQuery)
      && searchQuery.searchMode === 'SEARCH_REFERENCE_ID' && searchQuery.ids && searchQuery.ids.length > 0
      && searchQuery.ids.every(id => id.length <= 100);
  }

  private isValidFilenameSearchQuery(searchQuery): boolean {
    return RemitTransactionsSearchQuery.isNotEmptySearchQuery(searchQuery)
      && searchQuery.searchMode === 'SEARCH_FILENAME' && searchQuery.filename && searchQuery.filename.length > 0;
  }

  private isValidSortOrder(searchQuery): boolean {
    return searchQuery != null;
  }

  private resetView() {
    if (this.dataTableComponent) {
      this.dataTableComponent.primeTable.reset();
    }
  }

  private resetPagerInfo() {
    this.pagerInfo = PagerInfo.calcPagerInfo(0, 0, 25);
  }

  private createTableColumns(columnOrder: string[]) {
    return ErrRemitDataTableConfig.getColumns(this.docType, columnOrder);
  }

  private loadBucketDeals(searchQuery: RemitTransactionsSearchQuery) {
    this.hidden = false;
    const currentPage = this.currentPage;
    const resultsPerPage = this.perPage;
    this.changeDetectorRef.markForCheck();
    this.docType = searchQuery.remitDocumentType;
    searchQuery.paging.entriesPerPage = resultsPerPage;
    searchQuery.paging.page = currentPage;

    this.remitTransactionsService.fetchRemitBucketDetails(searchQuery)
      .subscribe((result: ErrRemitDataTableModel) => {
          this.dealsResult = this.formatDeals(result);
          this.pagerInfo = PagerInfo.calcPagerInfo(result.recordCount, currentPage, resultsPerPage);
          this.changeDetectorRef.markForCheck();
          this.tableColumns = this.createTableColumns(result.columnOrder);

        },
        err => {
          this.changeDetectorRef.markForCheck();
        });
  }

  private setSortOrder(searchQuery: RemitTransactionsSearchQuery) {
    this.sorting = searchQuery.sorting;
  }

  private searchByUti(searchQuery: RemitTransactionsSearchQuery) {

    this.searchBy('/api/err/remit/search/table1/byUtisAndOrderIds', searchQuery);
  }

  private searchByTransactionId(searchQuery: RemitTransactionsSearchQuery) {
    const docTypePath = this.getDocTypeApiPath(searchQuery.remitDocumentType);
    this.searchBy('/api/err/remit/search/' + docTypePath + '/byTransactionIds', searchQuery);
  }

  private searchByContractId(searchQuery: RemitTransactionsSearchQuery) {
    this.searchBy('/api/err/remit/search/table2/byUtis', searchQuery);
  }

  private searchByUniquesId(searchQuery: RemitTransactionsSearchQuery) {
    this.searchBy('/api/err/remit/search/table3/byUniqueId', searchQuery);
  }

  private searchByReferenceId(searchQuery: RemitTransactionsSearchQuery) {
    this.searchBy('/api/err/remit/search/fundamentals/byReferenceId', searchQuery);
  }

  private searchByFilename(searchQuery: RemitTransactionsSearchQuery) {

    const docTypePath = this.getDocTypeApiPath(searchQuery.remitDocumentType);

    this.searchBy('/api/err/remit/search/' + docTypePath + '/byFilename', searchQuery);
  }

  private searchByFilter(searchQuery: RemitTransactionsSearchQuery) {
    const docTypePath = this.getDocTypeApiPath(searchQuery.remitDocumentType);
    this.currentPage = searchQuery.paging.page;
    this.perPage = searchQuery.paging.entriesPerPage;
    this.searchBy('/api/err/remit/search/' + docTypePath + '/byCriteria', searchQuery);
  }

  private searchBy(apiUrl: string, searchQuery: RemitTransactionsSearchQuery) {
    this.hidden = false;
    searchQuery.paging.page = this.currentPage;
    searchQuery.paging.entriesPerPage = this.perPage;
    const currentPage = searchQuery.paging.page;
    const resultsPerPage = searchQuery.paging.entriesPerPage;
    this.changeDetectorRef.markForCheck();
    this.docType = searchQuery.remitDocumentType;
    this.resetPagerInfo();
    this.remitTransactionsService.searchBy(apiUrl, searchQuery)
      .subscribe((result: ErrRemitDataTableModel) => {
          this.dealsResult = this.formatDeals(result);
          this.pagerInfo = PagerInfo.calcPagerInfo(result.recordCount, currentPage, resultsPerPage);
          this.changeDetectorRef.markForCheck();
          this.tableColumns = this.createTableColumns(result.columnOrder);
          this.hidden = false;
          this.changeDetectorRef.detectChanges();
        },
        err => {
          this.changeDetectorRef.markForCheck();
        });
  }

  private formatDeals(data) {
    const rows = data.rows.map(row => ({
      ...row,
      creationTimestamp: DateUtils.transformDateWithTimestamp(row.creationTimestamp),
      lastUpdate: DateUtils.transformDateWithTimestamp(row.lastUpdate),
      elcomLastUpdate: DateUtils.transformDateWithTimestamp(row.elcomLastUpdate),
      transactionTimestampUtc: DateUtils.transformDateWithTimestamp(row.transactionTimestampUtc),
      transactionTimestamp: DateUtils.transformDateWithTimestamp(row.transactionTimestamp),
      timeIntervalStart: DateUtils.transformDateWithTimestamp(row.timeIntervalStart),
      timeIntervalEnd: DateUtils.transformDateWithTimestamp(row.timeIntervalEnd),
      deliveryStartDate: DateUtils.transformDate(row.deliveryStartDate),
      deliveryEndDate: DateUtils.transformDate(row.deliveryEndDate),
      startTimestampUtc: DateUtils.transformDateWithTimestamp(row.startTimestampUtc),
      endTimestampUtc: DateUtils.transformDateWithTimestamp(row.endTimestampUtc),
      periodStart: DateUtils.transformDateWithTimestamp(row.periodStart),
      periodEnd: DateUtils.transformDateWithTimestamp(row.periodEnd),

      price: this.decimalPipe.transform(row.price, '1.2-5'),
      notionalAmountValue: this.decimalPipe.transform(row.notionalAmountValue, '1.2-5')
    }));
    return { ...data, rows };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
    this.hidden = true;
    this.remitTransactionsService.emitNewSearchQuery(null);
  }

  public loadExcelExport(): void {
    this.remitTransactionsService.downloadExcelExport()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  isT1Selected(): boolean {
    return this.docType === 'REMIT_TABLE_1';
  }

  isT2Selected(): boolean {
    return this.docType === 'REMIT_TABLE_2';
  }

  isT3Selected(): boolean {
    return this.docType === 'ELECTRICITY_RIGHTS';
  }

  isT4Selected(): boolean {
    return this.docType === 'GAS_CAPACITY';
  }

  isT5Selected(): boolean {
    return this.docType === 'FUNDAMENTALS';
  }

  public loadRemitXml(historyId: number, docType: RemitDocumentType): void {
    this.remitTransactionsService.downloadRemitXml(historyId, docType)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        FileSaver.saveAs(data.blob, data.filename);
      });
  }

  public openExecutionsSearch(contractId: string) {

    const navigationExtras: NavigationExtras = {
      queryParams: { 'transactionId': contractId },
      fragment: 'anchor'
    };
    this.remitTransactionsService.setCachedSearchQuery('REMIT_TABLE_2', null);
    this.router.navigate(['/err/remit_transactions/table1'], navigationExtras);

  }

  private createQueryParams(documentType: RemitDocumentType, row: any) {
    const queryParams = {};

    if (row.id) {
      queryParams['historyId'] = row.id;
    }

    if (documentType === 'FUNDAMENTALS') {
      queryParams['documentType'] = row.documentType;
      queryParams['extId'] = row.extId;
    } else {

      queryParams['documentType'] = documentType;

    }

    switch (documentType) {
      case 'REMIT_TABLE_1':
        queryParams['displayValue'] = row.transactionId;
        break;
      case 'REMIT_TABLE_2':
        queryParams['displayValue'] = row.contractId;
        break;
      case 'ELECTRICITY_RIGHTS':
        queryParams['displayValue'] = row.transactionIdentifier;
        break;
      case 'GAS_CAPACITY':
        queryParams['displayValue'] = row.transactionIdentifier;
        break;
      case 'FUNDAMENTALS':
        queryParams['displayValue'] = row.documentType;
        break;
    }

    queryParams['urlPart'] = 'remit';

    return queryParams;
  }

  private getDocTypeApiPath(docType: string): string {
    let regime = 'table1';
    switch (docType) {
      case 'REMIT_TABLE_2':
        regime = 'table2';
        break;
      case 'ELECTRICITY_RIGHTS':
        regime = 'table3';
        break;
      case 'GAS_CAPACITY':
        regime = 'table4';
        break;
      case 'FUNDAMENTALS':
        regime = 'fundamentals';
        break;
      default:
        regime = 'table1';
    }

    return regime;
  }

  getRouterLinkModify(): string {
    let resultLink = '';
    if (this.isT1Selected()) {
      resultLink = '/err/remit_table1_trade/modify';
    } else if (this.isT2Selected()) {
      resultLink = '/err/remit_table2_trade/modify';
    }
    return resultLink;
  }

  getRouterLinkCancel(): string {
    let resultLink = '';
    if (this.isT1Selected()) {
      resultLink = '/err/remit_table1_trade/cancel';
    } else if (this.isT2Selected()) {
      resultLink = '/err/remit_table2_trade/cancel';
    }
    return resultLink;
  }

  getRouterLinkError(): string {
    let resultLink = '';
    if (this.isT1Selected()) {
      resultLink = '/err/remit_table1_trade/error';
    } else if (this.isT2Selected()) {
      resultLink = '/err/remit_table2_trade/error';
    }
    return resultLink;
  }

  public widthFit(text: string, body) {
    const postFix = 30;
    const textWidth = document
      .createElement('canvas')
      .getContext('2d')
      .measureText(text).width;

    return textWidth + postFix < body.offsetWidth;
  }
}
