import { BehaviorSubject, Observable, of, throwError as observableThrowError, lastValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { AuthenticationService } from '../auth/authentication.service';
import { UserInformationModel } from './user-informations.model';
import { ChangePasswordModel, ChangePasswordResponseModel } from '../preferences/change-password/change-password.model';
import { catchError } from 'rxjs/operators';
import { OrganisationService } from '@common/organisation.service';
import { EndPointConstants } from '@common/end-point-constants';
import { ErrorResponse } from '@common/error-response.model';
import { BrowserInfo } from '@common/browser-detection/browser-detection.model';

@Injectable()
export class UserService {

  private apiUrl = '/api/user/info';
  private apiUserAccountUrl = '/api/user/account';
  private apiUserPasswordUrl = '/api/user/password';
  private apiUnsupportedBrowser = '/api/user/unsupported/browser';
  public userInformation = new BehaviorSubject<UserInformationModel>(null);

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService, private organisationService: OrganisationService) {

    if (this.authenticationService) {
      this.authenticationService.isAuthenticated().subscribe(
        authenticated => {
          if (authenticated) {
            this.refresh();
          }
        }
      );
    }
  }

  getInformations(): Observable<UserInformationModel> {
    return this.userInformation.asObservable();
  }

  getCurrentInformations(): UserInformationModel {
    return this.userInformation.getValue();
  }

  public updateAccount(user: UserInformationModel): Observable<object> {

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    let params = new HttpParams();
    if (this.organisationService.getCurrentOrganisationId()) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, this.organisationService.getCurrentOrganisationId().toString());
    }

    const options = { headers: headers, params: params };
    return this.httpClient.post(this.apiUserAccountUrl, user, options).pipe().pipe(catchError(this.handleError));
  }

  public refresh() {
    this.fetchUserInformations().subscribe({
        next: data => this.userInformation.next(data),
        error: err => this.userInformation.error(err)
      }
    );
  }

  private fetchUserInformations(): Observable<UserInformationModel> {

    let params = new HttpParams();
    if (this.organisationService.getCurrentOrganisationId()) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, this.organisationService.getCurrentOrganisationId().toString());
    }

    if (this.authenticationService.hasXSRFToken()) {
      const options = { params: params };
      return this.httpClient.get(this.apiUrl, options)
        .pipe(catchError(this.handleError));
    }

    return of(null);
  }

  public updatePassword(changePassword: ChangePasswordModel): Observable<ChangePasswordResponseModel> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    let params = new HttpParams();
    if (this.organisationService.getCurrentOrganisationId()) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, this.organisationService.getCurrentOrganisationId().toString());
    }

    const options = { headers: headers, params: params };
    return this.httpClient.post<ChangePasswordResponseModel>(this.apiUserPasswordUrl, changePassword, options);
  }

  public logUnsupportedBrowser(unsupportedBrowser: BrowserInfo, organisation: string): Promise<ErrorResponse> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    let params = new HttpParams();
    if (organisation) {
      params = params.append(EndPointConstants.PARAM_ORGANISATION_ID, organisation);
    }

    const options = { headers: headers, params: params };
    return lastValueFrom(this.httpClient.post<ErrorResponse>(this.apiUnsupportedBrowser, unsupportedBrowser, options));
  }

  private handleError(error: HttpErrorResponse) {
    return observableThrowError(() => error);
  }

  public cleanUp() {
    this.userInformation.next(null);
  }
}
