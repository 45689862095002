<div class="panel-box margin-bottom-15 lg:max-w-[650px] lg:min-w-[400px]">
  <form [formGroup]="utiFilterFormGroup" (ngSubmit)="onSubmitUtiFilter()">
    <fieldset>
      <legend class="!font-bold" style="font-size: 14px">UTI</legend>
    </fieldset>
    <div class="row align-items-center">
      <div class="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-8">
        <textarea id="uti-input-field" class="form-control p-component padding-6" formControlName="uti" [ngClass]="displayFieldClass('uti')"></textarea>
      </div>
      <div class="col">
        <button
          id="btn-search-uti"
          type="submit"
          class="form-control btn margin-3 padding-6"
          [disabled]="!utiFilterFormGroup.valid">Search</button>
      </div>
    </div>
    <div class="row align-items-center justify-content-between">
      <div class="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-8">
        <div class="info-panel truncated-text">
          <span class="fa fa-info-circle padding-right-6"></span>Field accept lists of up to 50 IDs
        </div>
      </div>
      <div class="col">
        <button type="reset"
                id="btn-reset-uti"
                class="form-control btn margin-3 padding-6"
                (click)="onResetUtiFilter()">Reset</button>
      </div>
    </div>
    <div class="col-12">
      <p id="error-note-uti-invalid" class="error-note" *ngIf="isUtisNotRuleConformError()">Field 'Uti' is not rule conform.</p>
    </div>
  </form>
</div>
