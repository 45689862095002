import { DatatableState } from '@common/datatable/datatable.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { OrganisationService } from '@common/organisation.service';
import { MyFilter } from '@common/my-filters/my-filters.model';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { AddNote, Dispute, NotesCellViewEvent } from '@common/deals/deals.model';
import {
  EsmInvoiceFilterValues,
  EsmInvoiceFinder,
  EsmInvoiceFinderMyFilter,
  FilterBy,
  INVOICE_FINDER_DIALOGS,
  InvoiceFinderFilters,
  InvoiceFinderFiltersIdInputs,
  InvoiceFinderPropertiesFilters,
  KeyValueProp
} from './invoice-finder.model';
import { EsmInvoiceFinderState } from '../../state/invoice-finder/invoice-finder.reducers';
import * as fromFilterActions from '../../state/invoice-finder/filters/filters.actions';
import * as fromFilterSelectors from '../../state/invoice-finder/filters/filters.selectors';
import * as fromMyFiltersActions from '../../state/invoice-finder/my-filters/my-filters.actions';
import * as fromMyFiltersSelectors from '../../state/invoice-finder/my-filters/my-filters.selectors';
import * as fromTableSelectors from '../../state/invoice-finder/table/table.selectors';
import * as fromTableActions from '../../state/invoice-finder/table/table.actions';
import * as fromIFActions from '../../state/invoice-finder/invoice-finder.actions';
import * as fromDialogActions from '../../state/invoice-finder/dialog/dialog.actions';
import * as fromDialogSelectors from '../../state/invoice-finder/dialog/dialog.selectors';
import * as fromCommon from '@common/state/reducers';
import { ErrorMessageService } from '@common/errors/error-message.service';
import {  filter } from 'rxjs/operators';
import { Amounts } from '../../common/table-toast/table-toast.model';
import { OrganisationGroupModel } from '@common/organisation-group.model';

@Component({
  selector: 'cms-invoice-finder',
  templateUrl: './invoice-finder.component.html',
  styleUrls: ['./invoice-finder.component.scss'],
})
export class InvoiceFinderComponent implements OnInit, OnDestroy {
  filtersBy$: Observable<FilterBy> = this.store.pipe(select(fromFilterSelectors.getFilterBy));
  filtersProps$: Observable<InvoiceFinderPropertiesFilters> = this.store.pipe(select(fromFilterSelectors.getFilterProperties));
  commodities$: Observable<string[]> = this.store.pipe(select(fromFilterSelectors.getCommodities));
  counterParties$: Observable<KeyValueProp[]> = this.store.pipe(select(fromFilterSelectors.getCounterParties));
  invoiceTypes$: Observable<string[]> = this.store.pipe(select(fromFilterSelectors.getInvoiceTypes));
  deliveryPoints$: Observable<{ keyObject: string, valueObject: string }[]> = this.store.pipe(select(fromFilterSelectors.getDeliveryPoints));
  selectedIds$: Observable<string[]> = this.store.pipe(select(fromTableSelectors.getSelectedIds));
  dialog$: Observable<fromCommon.DialogState<INVOICE_FINDER_DIALOGS>> = this.store.pipe(select(fromDialogSelectors.getDialog));
  myFilters$ = this.store.pipe(select(fromMyFiltersSelectors.getAllMyFilters));
  defaultFilter$ = this.store.pipe(select(fromMyFiltersSelectors.getDefaultFilter));
  allDealsFilter$ = this.store.pipe(select(fromMyFiltersSelectors.getAllDealsFilter));
  notFoundIds$: Observable<string[]> = this.store.pipe(select(fromFilterSelectors.getNoFoundIds));
  idsCount$: Observable<number> = this.store.pipe(select(fromFilterSelectors.getIdsCount));
  inputIds$: Observable<InvoiceFinderFiltersIdInputs> = this.store.pipe(select(fromFilterSelectors.getInputIds));
  loaded$ = this.store.pipe(select(fromFilterSelectors.getLoaded));
  loading$ = this.store.pipe(select(fromTableSelectors.getLoading));
  messages$ = this.store.pipe(select(fromTableSelectors.getMessages));
  data$: Observable<DatatableData<EsmInvoiceFinder[]>> = this.store.pipe(select(fromTableSelectors.getData));
  paging$: Observable<PagingFilter> = this.store.pipe(select(fromTableSelectors.getPaging));
  sorting$: Observable<SortingFilter> = this.store.pipe(select(fromTableSelectors.getSorting));
  columns$: Observable<Column[]> = this.store.pipe(select(fromTableSelectors.getColumns));
  subscription$: Subscription = new Subscription();
  idFiltersLabels = [
    {label: 'Our Invoice Number', name: 'ourInvoiceNumber'},
    {label: 'Their Invoice Number', name: 'theirInvoiceNumber'},
    {label: 'Document ID', name: 'documentId'},
    {label: 'Purchase Order Number', name: 'purchaseOrderNumber'},
    {label: 'Trade ID/UTI (from line items)', name: 'tradeId'}
  ];

  private selectedInvoices: EsmInvoiceFinder[] = [];
  selected$: Observable<EsmInvoiceFinder[]> = this.store.pipe(select(fromTableSelectors.getSelectedInvoices));
  selected: Amounts[] = [];
  isMultiAgreeVisible : boolean = false;
  private remoteQuery;

  get selectedRows(){
    return this.selectedInvoices;
  }

  set selectedRows(rows: EsmInvoiceFinder[]) {
    this.selectedInvoices = rows;
    this.onSelected(rows);
  }


  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store<EsmInvoiceFinderState>,
              private organisationService: OrganisationService,
              public errorMessageService:ErrorMessageService) {

    if(hasData(router)){
      this.remoteQuery = getSearchIds(this.router);
    }
  }

  onLazyLoad({page, perPage, sortField, sortOrder}: DatatableState): void {
    this.store.dispatch(fromTableActions.sortAndPageDataAction({
      paging: {
        page,
        entriesPerPage: perPage
      },
      sorting: {
        sortOrder,
        columnName: sortField
      }
    }));
  }

  onClickCustomize(): void {
    this.router.navigate(['/settings/datatable/customize'], {queryParams: {documentType: 'ESM_INVOICE'}});
  }

  onExport(): void {
    this.store.dispatch(fromTableActions.exportAction());
  }

  ngOnInit(): void {

    // if first login
    if(!!this.remoteQuery){
      this.store.dispatch(fromFilterActions.filterAction({
        filterBy: 'theirInvoiceNumber',
        inputIds: { theirInvoiceNumber: this.remoteQuery}
      }));
    }


    this.subscription$.add(this.organisationService.onRefresh().pipe(
      filter((auth) => auth && this.organisationService.getCurrentOrganisationId() !== null))
      .subscribe(() => {
        this.store.dispatch(fromIFActions.setToDefaultAction());
        this.store.dispatch(fromFilterActions.getFiltersValuesAction());
        this.store.dispatch(fromMyFiltersActions.loadMyFiltersAction());

        // on re-enter screen, preserve dispatch order
        if(!!this.remoteQuery){
          this.store.dispatch(fromFilterActions.filterAction({
            filterBy: 'theirInvoiceNumber',
            inputIds: { theirInvoiceNumber: this.remoteQuery}
          }));
        }

      }));
    this.subscription$.add( this.data$.subscribe(data => {
      if (data.values && this.selectedInvoices) {
        const ids = this.selectedInvoices.map(s => s.id);
        const selected: EsmInvoiceFinder[] = data.values.filter(d => ids.indexOf(d.id) !== -1);
        this.onSelected(selected);
      }
    }));
    this.subscription$.add(this.filtersProps$.subscribe(props => {
      if (props.webUser) {
        this.idFiltersLabels = [
          {label: 'Their Invoice Number', name: 'theirInvoiceNumber'},
          {label: 'Document ID', name: 'documentId'},
          {label: 'Purchase Order Number', name: 'purchaseOrderNumber'},
          {label: 'Trade ID/UTI (from line items)', name: 'tradeId'}
        ];
      } else {
        this.idFiltersLabels = [
          {label: 'Our Invoice Number', name: 'ourInvoiceNumber'},
          {label: 'Their Invoice Number', name: 'theirInvoiceNumber'},
          {label: 'Document ID', name: 'documentId'},
          {label: 'Purchase Order Number', name: 'purchaseOrderNumber'},
          {label: 'Trade ID/UTI (from line items)', name: 'tradeId'}
        ];
      }
      this.isMultiAgreeVisible = props.multiAgreeButtonVisible;
    }));
  }


  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
    this.store.dispatch(fromIFActions.setToDefaultAction());
    this.selectedInvoices = [];
  }

  onOpenSaveCurrent(): void {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'SAVE_CURRENT'
    }));
  }

  onSelectMyFilter(myFilter:MyFilter): void {
    this.store.dispatch(fromMyFiltersActions.selectMyFilterAction(filterQuery(myFilter) as EsmInvoiceFinderMyFilter));
  }

  onConfirmReplaceCurrent(myFilter: MyFilter) {
    this.store.dispatch(fromMyFiltersActions.replaceMyFiltersAction(myFilter as EsmInvoiceFinderMyFilter));
  }

  onSetToDefault(myFilter: MyFilter): void {
    this.store.dispatch(fromMyFiltersActions.setDefaultFilterAction(myFilter as EsmInvoiceFinderMyFilter));
  }

  onConfirmSaveCurrent(myFilter: MyFilter): void {
    this.store.dispatch(fromMyFiltersActions.saveMyFiltersAction(myFilter as EsmInvoiceFinderMyFilter));
  }

  onManageFilters(): void {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'MANAGE_FILTERS'
    }));
  }

  onDeleteFilter(filterName: string): void {
    this.store.dispatch(fromMyFiltersActions.deleteMyFilterAction({filterName}));
  }

  onCloseMyFiltersDialog(): void {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }

  onFilterByProp(propFilters: InvoiceFinderPropertiesFilters): void {
    this.store.dispatch(fromFilterActions.filterAction({
      propFilters,
      filterBy: 'dealProperties'
    }));
  }

  onResetFilters(): void {
    this.store.dispatch(fromFilterActions.clearPropertiesFiltersAction());
  }

  onResetByIds(): void {
    this.store.dispatch(fromFilterActions.clearIdPropertiesFiltersAction());
  }

  onFilterByIds({filterBy, ...inputIds}: InvoiceFinderFilters): void {
    this.store.dispatch(fromFilterActions.filterAction({
      inputIds,
      filterBy
    }));
  }

  onDownloadXml({id}: EsmInvoiceFinder): void {
    this.store.dispatch(fromTableActions.downloadXmlAction(id));
  }

  onDownloadPdf({id}: EsmInvoiceFinder): void {
    this.store.dispatch(fromTableActions.downloadPdfAction(id));
  }

  resendDocument({id}: EsmInvoiceFinder) {
    this.store.dispatch(fromTableActions.resendAction({ id, screen: 'invoice' }));
  }

  getDisplayParams({id, documentId}: EsmInvoiceFinder): object {
    return {
      displayValue: documentId,
      urlPart: 'esm/invoice',
      invoiceId: id
    };
  }

  onOpenAddNoteDialog(): void {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'ADD_NOTES',
      messages: [],
    }));
  }

  onViewNotes(data: NotesCellViewEvent): void {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'VIEW_NOTES',
      messages: [],
      data
    }));
  }

  onSelected(invoices: EsmInvoiceFinder[]) {
    this.selectedInvoices = invoices;
    let confirmationIds: string[] = [];

    if (invoices) {
      confirmationIds = invoices.map(d => d.id + '');
      this.selected = invoices.map(amounts);
    }

    this.store.dispatch(fromTableActions.setSelectedIdsAction({confirmationIds}));
    this.store.dispatch(fromTableActions.setSelectedInvoicesAction({invoices}));
  }

  onConfirmAddNotes(obj: AddNote): void {
    this.store.dispatch(fromTableActions.addNoteAction(obj));
  }

  onCancelAddNotes() {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }

  onCancelViewNotes(): void {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }


  getCompareParams(row): Params {
    const {referenceInvoiceId} = this.route.snapshot.queryParams;
    return {
      leftInvoiceId: referenceInvoiceId,
      rightInvoiceId: row.id
    };
  }

  onOpenMisComparer(queryParams: any) {
    this.router.navigate(['../compare'], {
      queryParams,
      relativeTo: this.route
    });
  }

  agreeItem(invoice : EsmInvoiceFinder) {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'AGREE',
      messages: [],
      data: [invoice]
    }));
  }

  disputeItem(invoice : EsmInvoiceFinder) {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'DISPUTE',
      messages: [],
      data: invoice
    }));
  }

  onOpenMultiAgreeDialog() {
    this.store.dispatch(fromDialogActions.openDialogAction({
      name: 'AGREE',
      messages: [],
      data : this.selectedInvoices
    }));
  }

  onCancelAgree() {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }

  onConfirmAgree(confirmationIds: string[]) {
    this.store.dispatch(fromTableActions.confirmAgreeInvoices({invoiceIds: confirmationIds}));
  }

  onCancelDispute() {
    this.store.dispatch(fromDialogActions.closeDialogAction());
  }

  onConfirmDispute(data: Dispute) {
    this.store.dispatch(fromTableActions.confirmDisputeInvoice(data));
  }

}

  // ======= Helper ======= //

function amounts(item: EsmInvoiceFinder): Amounts {
  return ({
    vatRat: item.vatRate,
    gross: item.totalGrossAmount,
    vat: item.vatAmount,
    grossCur: item.totalGrossAmountCurrency,
    vatCur: item.vatAmountCurrency
  });
}

function getSearchIds(router: Router) {
  return JSON.parse(router.getCurrentNavigation().extras.state.targetRequestParams).inputIds;
}

function hasData(router: Router): boolean {
  return !!router.getCurrentNavigation()?.extras.state?.targetRequestParams;
}

function filterQuery(myFilter){
  return !!myFilter ? {
    ...myFilter,
    deliveryPointFilter: myFilter.deliveryPointFilter.map(({ key, value }) => ({ keyObject: key, valueObject: value }))
  } : null;
}
