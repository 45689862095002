import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Message } from 'primeng/api';
import { toPayload } from '@common/cms-common.model';
import { ApiRequestService } from '@common/api-request.service';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { DatatableData } from '@common/shared/results.model';
import { Prefix } from '../../reducers';
import { select, Store } from '@ngrx/store';
import { CmsBillingRunsState } from './cms-runs.reducers';
import { DatatableState } from '../../datatable/datatable.reducer';
import * as fromDtbActions from '../../datatable/datatable.actions';
import * as fromDialogActions from '../../dialog/dialog.actions';
import * as fromSelectors from './cms-runs.selectors';
import * as fromActions from './cms-runs.actions';
import {
  GetBillingRunsRequest,
  GetBillingRunsResponse,
  GetBillingRunErrorsResponse,
  BillingRuns,
  ErrorModalData,
  GetBillingRunDefaultResponse,
  BillingRunStatus,
  BillingRunParams,
  StartBillingRun
} from '../../../administration-tab-panel/billing-runs/cms/billing-runs.model';
import * as FileSaver from 'file-saver';
import { ErrorResponse } from '@common/error-response.model';
import { getFilenameFromHeader } from '@common/deals/deals.model';

@Injectable()
export class CmsBillingRunsEffects {
  private baseUrl = '/api/administration/billingruns';
  private prefix: Prefix = '[BILLING RUNS]';

  constructor(private apiGateway: ApiRequestService,
    private actions$: Actions,
    private store$: Store<CmsBillingRunsState>,
    private mapper: MessagesMapperService) { }

   loadData$ = createEffect(() => this.actions$
    .pipe(
      ofType(`${this.prefix} ${fromDtbActions.LOAD_DATA}`),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getDatatable))),
      switchMap(([, { paging }]: [fromDtbActions.LoadDataAction, DatatableState]) => {
        return this.loadData({ paging });
      })));

   downloadZipAction$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.downloadZipAction.type),
      map(toPayload),
      concatMap((value: number) => {
        let params = new HttpParams();
        params = params.set('billingRunId', value.toString());
        return this.apiGateway.getBlob(`${this.baseUrl}/zip`, null, params)
          .pipe(switchMap((response: any) => {
            const filename = getFilenameFromHeader(response.headers);
            const blob: Blob = response.body;
            FileSaver.saveAs(blob, filename);
            return of(new fromDtbActions.SetMessagesAction(this.prefix, []));
          }),
            catchError(error =>
              of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorDownloadMessage()]))
            ));
      })
    ));

   sortAndPage$ = createEffect(() => this.actions$
    .pipe(ofType(`${this.prefix} ${fromDtbActions.SORT_AND_PAGE_DATA}`),
      withLatestFrom(this.store$.pipe(select(fromSelectors.getPaging))),
      switchMap(([, paging]: [fromDtbActions.SortAndPageDataAction, any]) => {
        return this.loadData({ paging });
      }
      )));

   deleteBillingRun$ = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.deleteBillingRunAction.type),
      map(toPayload),
      concatMap((value: number) => {
        const params = new HttpParams().set('billingRunId', value.toString());
        return this.apiGateway.delete(`${this.baseUrl}/delete`, params)
          .pipe(
            switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromDtbActions.SetMessagesAction(this.prefix, messages)];
              }
              return [
                fromActions.getBillingRunStatus(),
                new fromDtbActions.LoadDataAction(this.prefix),
                new fromDialogActions.CloseDialogAction(this.prefix),
                new fromDtbActions.SetMessagesAction(this.prefix, this.mapper.toSuccessMessages('Deleted.'))
              ];
            }),
            catchError(error =>
              of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
            )
          );
      })));

   sendInvoiceMails = createEffect(() => this.actions$
    .pipe(ofType(fromActions.sendInvoiceMailsAction.type),
      map(toPayload),
      concatMap((value: number) => {
        const params = new HttpParams().set('billingRunId', value.toString());
        return this.apiGateway.post(`${this.baseUrl}/mailing`, null, params)
          .pipe(
            switchMap((response: ErrorResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [
                  new fromDtbActions.SetMessagesAction(this.prefix, messages),
                  new fromDtbActions.LoadDataAction(this.prefix)
                ];
              }
              return [
                new fromDtbActions.SetMessagesAction(this.prefix, this.mapper.toSuccessMessages('Sending invoice mails was successful.')),
                new fromDtbActions.LoadDataAction(this.prefix),
                fromActions.getBillingRunStatus()
              ];
            }),
            catchError(error => {
              const message = error.errorMessage || 'Sending invoice mails finished with errors.';
              return of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(message)]));
            })
          );
      })));


   getBillingRunErrors = createEffect(() => this.actions$
    .pipe(ofType(fromActions.getBillingRunErrors.type),
      map(toPayload),
      concatMap((billingRun: BillingRuns) => {

        const params = new HttpParams().set('billingRunId', billingRun.billingRunId.toString());

        return this.apiGateway.get(`${this.baseUrl}/errors`, params)
          .pipe(
            map((response: GetBillingRunErrorsResponse) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return new fromDtbActions.SetMessagesAction(this.prefix, messages);
              }
              const data: ErrorModalData = {
                values: response.values,
                item: billingRun
              };
              return new fromDialogActions.OpenDialogAction(this.prefix, { name: 'STATE', data });
            }),
            catchError(error => of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
            )
          );
      })));

   getBillingRunStatus = createEffect(() => this.actions$
    .pipe(ofType(fromActions.getBillingRunStatus.type),
      switchMap(() => {
        return this.apiGateway.get(`${this.baseUrl}/preset`)
          .pipe(
            switchMap((response: GetBillingRunDefaultResponse<BillingRunStatus>) => {
              const {progressOfCurrentRun, templatesExists } = response.value;
              const payload = !(progressOfCurrentRun > 0 && progressOfCurrentRun < 100) && templatesExists && response.value.billingRunAllowed;
              const permissionAction = fromActions.setBillingRunPermission(payload);
              if (!templatesExists) {
                const message = 'Templates for billing run not found. Please contact the technical team';
                return [
                  permissionAction,
                  new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(message)])
                ];
              }
              if (progressOfCurrentRun > 0 && progressOfCurrentRun < 100) {
                const message = `A billing run is in progress (${progressOfCurrentRun}%). To update progress please refresh the screen.`;
                return [
                  permissionAction,
                  new fromDtbActions.SetMessagesAction(this.prefix, this.mapper.toWarningMessages(message))
                ];
              }
              return [permissionAction];
            }),
            catchError(error =>
              of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
            )
          );
      })));

   editBillingRunParams = createEffect(() => this.actions$
    .pipe(
      ofType(fromActions.editBillingRunParams.type),
      map(toPayload),
      switchMap((data: BillingRunParams) => {
        return this.apiGateway.put(`${this.baseUrl}/editParameters`, data)
          .pipe(
            switchMap((response: GetBillingRunDefaultResponse<BillingRunParams>) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [new fromDialogActions.UpdateDialogAction(this.prefix, messages)];
              }
              return [
                fromActions.setBillingRunParams(response.value),
                new fromDialogActions.CloseDialogAction(this.prefix),
                new fromDtbActions.SetMessagesAction(this.prefix, this.mapper.toSuccessMessages('Billing parameters have been updated.'))
              ];
            }),
            catchError(error => {
              const message = error.errorMessage || 'Editing of billing parameters failed.';
              return of(new fromDialogActions.UpdateDialogAction(this.prefix, [this.mapper.createErrorMessage(message)]));
            })
          );
      })
    ));

   getBillingRunParams = createEffect(() => this.actions$
    .pipe(ofType(fromActions.getBillingRunParams.type),
      switchMap(() => {
        return this.apiGateway.get(`${this.baseUrl}/loadParameters`)
          .pipe(
            map((response: GetBillingRunDefaultResponse<BillingRunParams>) => {
              return fromActions.setBillingRunParams(response.value);
            }),
            catchError(error =>
              of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
            )
          );
      })));

   startBillingRun = createEffect(() => this.actions$
    .pipe(ofType(fromActions.startBillingRun.type),
      map(toPayload),
      switchMap((data: StartBillingRun) => {
        return this.apiGateway.post(`${this.baseUrl}/startBillingRun`, data)
          .pipe(
            switchMap((response: ErrorResponse | any) => {
              const messages: Message[] = this.mapper.toErrorMessages(response);
              if (messages && messages.length) {
                return [
                  fromActions.getBillingRunStatus(),
                  new fromDtbActions.SetMessagesAction(this.prefix, messages)
                ];
              }
              return [
                new fromDtbActions.SetMessagesAction(this.prefix, this.mapper.toSuccessMessages(
                  'A billing run has been started. Please refresh the screen to view its current status.')),
                fromActions.setStartBillingRunModel(null),
                fromActions.getBillingRunStatus(),
                new fromDtbActions.LoadDataAction(this.prefix)
              ];
            }),
            catchError(error => {
              const message = error.errorMessage || 'Billing run failed.';
              const errorAction = new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(message)]);
              return of(errorAction, fromActions.getBillingRunStatus());
            })
          );
      })));

  private loadData(request: GetBillingRunsRequest) {
    return this.apiGateway.post(`${this.baseUrl}/data`, request)
      .pipe(
        map((response: GetBillingRunsResponse) => {
          const messages: Message[] = this.mapper.toErrorMessages(response);
          if (messages && messages.length) {
            return new fromDtbActions.SetMessagesAction(this.prefix, messages);
          }
          return new fromDtbActions.LoadDataSuccessAction(this.prefix, response as DatatableData<BillingRuns[]>);
        }),
        catchError(error =>
          of(new fromDtbActions.SetMessagesAction(this.prefix, [this.mapper.createErrorMessage(error.errorMessage)]))
        )
      );
  }
}
