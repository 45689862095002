
<p-messages class="ms-0" [value]="messages$ | async"  [id]="'acerbillingmessages'"[closable]="true" (click)="clear()"></p-messages>

<cms-info ngPreserveWhitespaces>
  When a billing run is started, several pre-checks are made to ensure that all data required for the run is present:

  * Does neither a draft nor a final run exist yet?
  * Are there any transactions that were sent to ACER in the past quarter and for which no response has yet been received?
    * If this is the case, the run is stopped and the user has to either confirm or cancel the run via the ```!``` button. If the run is continued, these transactions will never be billed if ACER replies at a later point with an ACER_SUBMISSION_TIMESTAMP in the past quarter
  * Is billing master data available for all org groups with active eRR organisations (except SMTs)?
    * If this is not the case, at least one invoice cannot be sent out
  * If all pre-checks are successful (or superseded by the user), the actual billing run begins.

  Once a billing run has been started, it is shown in the table of runs and the current state is shown as a series of steps.

  __Breakdown of the steps and their meaning:__

  * Step 1 – Pre-checks are starting
  * Step 2 – Pre-checks are running
  * Step 3 – FHA needs to confirm if run should be continued despite findings in pre-check; run will stop here until user action has been taken
  * Step 4 – Billing run is starting
  * Step 5 – Data on ACER reports is being collected
  * Step 6 – Reports are assigned to organisation groups
  * Step 7 – Bucket fees are calculated
  * Step 8 – Total fees are calculated
  * Step 9 – Invoices are generated
  * Step 10 – Run is completing

  ACER groups transactions by Beneficiary/MP and OMP and then bills based on the total count of transactions within a group (e.g. 1-1,000 transactions cost EUR 250, 1,001-10,000 transactions cost EUR 500). Therefore, the billing run does the same. The following grouping rules apply:

  * Group by MP
    * If there is a beneficiary and the beneficiary is a full eRR party, the beneficiary is used as the MP value
    * If there is no beneficiary, use the MP as the MP value for grouping
  * Group by OMP
    * If OMP is XBIL or null, the OMP value is set to 'Supply contracts - outside OMP'
    * For T3 and T4 contracts, the OMP value is set to 'Transportation contracts'

  This results in a count for every combination of Beneficiary/MP and OMP that exists in transactions of the past quarter. These are then allocated to the organisation group that they will be billed to. In general, the following broad rules apply:
  * SMTs are never billed, neither if they are a beneficiary nor if they are an MP; in this case, the org group of the sender is billed
  * If a report is on behalf of a full eRR party and there is no beneficiary in the report, the org group of the party on behalf of whom the report is made is billed
  * If a report is on behalf of a full eRR party and there is a beneficiary in the report, either the beneficiary's org group or the sender's org group is billed
</cms-info>

<section class="panel-box flex align-items-stretch pe-4 overflow-auto" style="height: 5rem;max-width: 53rem">


  <form [formGroup]="form" class="flex flex-nowrap" >

  <div class="p-2 flex align-items-center" >
    <label for="acerInvoiceStartNumber" class="pe-4 mb-0" style="white-space: nowrap; font-weight:bold">Invoice Start Number: <span style="color: red">*</span></label>
    <input id="acerInvoiceStartNumber" formControlName="input" type="text" maxlength="22" cmsSimpleDigitOnly [pattern]="'^[0-9]{1,16}$'" [dotAllowed]="false" style="height: 1.8rem">
  </div>
  <div class="p-2 flex align-items-center ">
    <p-checkbox [formControl]="form.controls['draft']" id="ch-input"  [inputId]="'ch-input-inner'"  [binary]="true" ></p-checkbox>
    <label for="ch-input" class=" mb-0">Draft</label>
  </div>

  </form>

  <div class="p-2 flex align-items-center ms-auto">
    <button pButton class="btn" (click)="submit()" label="Start ACER Billing Run" [loading]="billingRunTriggered" [disabled]="!(presets$| async).billingRunAllowed">
    </button>
  </div>

</section>


<section class="pt-3 mb-3">
    <cms-datatable [scrollable]="false"
                   [tableColumns]="columns"
                   [currentPage]="(paging$ | async).page"
                   [rows]="(paging$ | async)?.entriesPerPage"
                   [sortField]="(sorting$ | async).columnName"
                   [sortOrder]="(sorting$ | async).sortOrder"
                   [loading]="(loading$ | async).table"
                   [data]="(data$ | async)?.values"
                   [totalRecords]="(data$ | async)?.count"
                   [rowsPerPageOptions]=" [10,25,50,100]"
                   [autoLayout]="true"
                   [lazy]="true"
                   [lazyLoadOnInit]="false"
                   (lazyLoad)="onLazyLoad($event)">

      <ng-template pTemplate="caption">

        <div style="text-align: right">
          <button pButton
                  class="btn reload_btn"
                  type="button"
                  [icon]="(loading$| async).table?'fa fa-refresh fa-spin':'fa fa-refresh'"
                  id="acer-billing-run-reload-button"
                  title="Reload table"
                  (click)="refreshTable()"></button></div>
      </ng-template>

      <ng-template pTemplate="body" let-row="row" let-column="column" let-rowIndex="rowIndex">

        <td *ngIf="column.type === 'button' && column.name === 'errors'" [ngStyle]="column.style" title="Errors">
          <ng-container *ngIf="row.errors>0; else black"><a (click)="previewErrors(row)">{{row.errors}} </a></ng-container>
          <ng-template #black><span style="font-style:italic">{{row.errors}}</span></ng-template>
        </td>


        <td *ngIf="column.type === 'button' && column.name === 'deleteButtonVisible'" [ngStyle]="column.style">
          <div class="inline-block">
            <button *ngIf="row.deleteButtonVisible" (click)="openDeleteConfirmDialog(row)"
                    pButton
                    icon="fa fa-trash"
                    id="acer-billing-run-delete-button-{{rowIndex}}"
                    title="Delete"
                    type="button"></button></div></td>


        <td *ngIf="column.type === 'button' && column.name === 'reportButtonVisible'"[ngStyle]="column.style">
          <div class="inline-block">
            <a routerLink="/admin/mails_report/table" *ngIf="row.reportButtonVisible"
               [queryParams]="{acerBillingRunId:row.acerBillingRunId, quarter:row.quarter}" target="_blank">
              <button
                pButton
                icon="fa fa-search"
                id="acer-billing-run-report-button-{{rowIndex}}"
                title="Report"
                type="button"></button></a></div></td>

        <td *ngIf="column.type === 'button' && column.name === 'mailingButtonVisible'" [ngStyle]="column.style">
          <div class="inline-block">
            <button *ngIf="row.mailingButtonVisible"
                    pButton
                    [disabled]="!row.mailingButtonEnabled"
                    icon="fa fa-envelope"
                    id="acer-billing-run-send-button-{{rowIndex}}"
                    title="Send invoice mails"
                    [loading]="sendTriggered"
                    type="button" (click)="triggerMailing(row.acerBillingRunId)"></button></div></td>

        <td *ngIf="column.type === 'button' && column.name === 'downloadButtonVisible'" [ngStyle]="column.style">
          <div class="inline-block">
            <button *ngIf="row.downloadButtonVisible"
              pButton
              icon="fa fa-file"
              id="acer-billing-run-download-button-{{rowIndex}}"
              title="Download"
              type="button" (click)="downloadZip(row.acerBillingRunId)"></button></div></td>

        <td *ngIf="column.type === 'button' && column.name === 'notProcessedByAcer'" [ngStyle]="column.style">
          <div class="inline-block">
            <span>{{row.notProcessedByAcer}}</span>
            <button *ngIf="row.notProcessedByAcer > 0 && row.deleteButtonVisible && row.progress !== 'FINISHED' && row.progress !== 'INCOMPLETE'"
                    pButton
                    icon="fa fa-exclamation"
                    id="acer-billing-run-confirm-button-{{rowIndex}}"
                    title="Confirm"
                    type="button" (click)="openConfirmBillingRunDialog(row)"></button></div></td>


      </ng-template>

      <ng-template pTemplate="customEmptyMessage">

        <tr>
          <td colspan="100" *ngIf="!(loading$ | async).table">
            No records found</td></tr>

      </ng-template>

    </cms-datatable>

</section>

<p-dialog [(visible)]="displayTransactionsNotProcessedWarning" [modal]="true" header="Are you sure that you want to perform this action?" [closable]=true id="transactionsNotProcessedWarningDialog"
          [style]="{width: '35%','max-width': '50rem','filter': 'drop-shadow(5px 5px 5px #777)'}">
  <p-messages [value]="confirmMessage" [closable]="false" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" class="mt3 mb3 block"></p-messages>
  <div class="flex reverse full-width justify-content-end">
    <button pButton class="btn block  mt-3" (click)="confirmCancel()" label="No - Abort billing run"></button>
    <button pButton class="btn block mt-3 ms-3" (click)="confirmTransactionsNotProcessedWarning()" label="Yes - Start billing run"></button>
  </div>
</p-dialog>

<p-dialog [(visible)]="displayDeleteDialog" (onHide)="reset()" [modal]="true" [header]="deleteHeader" [closable]=false id="deleteDialog" [style]="{width: '35%','max-width': '50rem','filter': 'drop-shadow(5px 5px 5px #777)'}">
  <p-messages [value]="deleteMessages" [closable]="false"  [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" class="mt3 mb3 block"></p-messages>

  <div class="flex reverse full-width justify-content-end">
    <button pButton class="btn block  mt-3" (click)="closeDelete()" label="Cancel" [disabled]="deleteTriggered"></button>
    <button *ngIf="!hideDeleteConfirmButton" pButton class="btn block mt-3 ms-3" (click)="confirmDelete()" label="Yes - Delete" [loading]="deleteTriggered"></button>
  </div>

</p-dialog>

