export class StringUtils {

  public static tokenizeString(str: string, regex: string | RegExp): string[] {
    if (regex == null) {
      throw new Error('regex must not be null');
    }
    if (!str) {
      return [];
    }
    return str.trim().split(regex);
  }

  public static tokenizeByWhitespace(str: string): string[] {
    return StringUtils.tokenizeString(str, /\s+/);
  }

  public static tokenizeByCommaOrLineBreaks(str: string): string[] {
    return str ? str.split(/[\r?\n,]+/).map(s => s.trim()).filter(s => s.length > 0) : [];
  }

  /**
   * example: camelCaseToSnakeUpperCase('helloWorldWhatsUp');
   * becomes: 'HELLO_WORLD_WHATS_UP'
   *
   * @param str
   */
  public static camelCaseToSnakeUpperCase(str: string) {
    if (!str) {
      return '';
    }
    const upperChars = str.match(/([A-Z])/g);
    if (!upperChars) {
      return str.toUpperCase();
    }

    for (let i = 0, n = upperChars.length; i < n; i++) {
      str = str.replace(new RegExp(upperChars[i]), '_' + upperChars[i].toLowerCase());
    }

    if (str.slice(0, 1) === '_') {
      str = str.slice(1);
    }

    return str.toUpperCase();
  }
}
