<div class="panel-box margin-bottom-15 lg:max-w-[715px]">
  <form [formGroup]="dateRangeFilterFormGroup" (ngSubmit)="onSubmitDateRangeFilter()">
    <fieldset>
      <legend class="!font-bold" style="font-size: 14px">Trade Date</legend>
    </fieldset>
    <div class="row align-items-center justify-content-between gutter-6">

      <div class="col col-sm-6 col-md-4">
        <p-calendar
          placeholder="From Date"
          inputId="fromTradeDate"
          formControlName="fromTradeDate"
          showIcon="true"
          monthNavigator="true"
          yearNavigator="true"
          dateFormat="dd/mm/yy"
          cmsDateRestriction>
        </p-calendar>
      </div>
      <div class="col col-sm-6 col-md-4">
        <p-calendar
          placeholder="To Date"
          inputId="toTradeDate"
          formControlName="toTradeDate"
          showIcon="true"
          monthNavigator="true"
          yearNavigator="true"
          dateFormat="dd/mm/yy"
          cmsDateRestriction>
        </p-calendar>
      </div>
      <div class="col-12 col-md-4">
        <div class="row justify-content-end gutter-3">
          <div class="col-6 col-md-6">
            <button type="submit"
              id="btn-search-date-filter"
              class="form-control btn margin-3 padding-6">Search</button>
          </div>
          <div class="col-6  col-md-6">
            <button type="button"
                    id="btn-reset-date-filter"
                    class="form-control btn margin-3 padding-6"
                    (click)="onResetDateRangeFilter()">Reset</button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p class="error-note" *ngIf="isFromTradeDateRequiredError()">Field 'From Date' is required.</p>
        <p class="error-note" *ngIf="isToTradeDateRequiredError()">Field 'To Date' is required.</p>
        <p id="msg-error-date-filter-order" class="error-note" *ngIf="isToTradeDateIsNotAfterOrEqualFromTradeDateError()">Trade 'to date' has to be after or equal to 'from date'.</p>
      </div>
    </div>
  </form>
</div>
